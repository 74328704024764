.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: 9999;
}
  
.content {
    justify-content: center;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.size{
    width: 80vh;
}

.modal-btn{
    margin-top: 1rem;
}

@media screen and (max-width: 940px) {
    .size{
        width: 60vh;
    }
}

@media (max-width: 600px) {
    .modal-content {
      width: 90%;
    }

    .size{
        width: 100%;
    }
}