.scroll-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: tomato;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    display: none;
    font-size: 24px;
}

.scroll-to-top:hover {
    background-color: tomato;
}

.visible {
    display: block;
}
.scroll-to-top i {
    -webkit-animation: mover 0.5s infinite alternate;
    animation: mover 0.5s infinite alternate;
}

@keyframes mover {
    100% {
      transform: translateY(-5px);
    }
    0% {
      transform: translateX(0px);
    }
}