.news-section {
  background-position: center;
  position: relative;
  margin-top: 50px;
}
.news-section::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
}
a{
  color: inherit;
  text-decoration: none;
}
a:hover{
  text-decoration: none;
  color: inherit;
}
.footer-image {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: -110px;
}
.footer-image img {
  max-width: 100%;
  height: 130px;
}

.news-section .testi-item {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  margin-bottom: 1rem;
    margin-top: 0.5rem;
  padding: 30px;
  border-radius: 50px 50px 50px 0px;
}

.news-section .testi-item p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin: 0 0 10px;
  font-style: italic;
}
.news-section .testi-item p .fa-quote-left {
  margin-right: 5px;
  color: tomato;
}
.news-section .testi-item p .fa-quote-right {
  margin-left: 5px;
  color: tomato;
}

.news-section .testi-comment .stars {
  margin: 0 0 20px;
}
.news-section .testi-comment .stars li {
  display: inline-block;
  margin: 0 1px;
}
.news-section .testi-comment .stars li i {
  font-size: 13px;
  color: tomato;
}
.news-section .client-info {
  position: relative;
  padding-left: 80px;
  padding-top: 5px;
  min-height: 60px;
}
.news-section .client-info img {
  height: 70px;
  width: 70px;
  border: 2px solid transparent;
  border-color: black;
  left: 0;
  top: 0;
  border-radius: 50%;
  position: absolute;
}
.news-section .client-info h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 2px;
}
.news-section .client-info p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin: 0;
}

.owl-nav {
  display: none;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

button.player-btn {
  height: 2.5rem;
  width: 2rem;
  background-color: white;
  border: none;
}
i.fa-brands.fa-youtube{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate( -50%, -50% );
  z-index: 5;
  font-size: 4em;
  color: #ff0000;
}