.footer{
  margin-top: 1rem;
  padding-bottom: 0.1rem;
  text-align: center;
}

.footer a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}