.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  height: 70vh;
  overflow-y: auto;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  margin: 10%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
