.header-container {
  display: flex;
  justify-content: center;
  height: 149px;
  color: var(--white);
}
.header-parent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 70%;
  color: var(--white);
}
.header-logo {
  font-size: 50px;
  font-family: "Poppins ExtraBold";
  color: var(--white);
  text-align: center;
}
.header-options {
  display: flex;
  align-items: center;
}
.header-option {
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}
.header-hamburger {
  display: none;
  color: var(--white);
}
.header-option-seperator {
  margin: 0 50px 0 0;
  color: var(--white);
}
.selected-header-option {
  color: var(--dark-orange);
}

.logo{
  width: 125px;
  height: 125px;
  margin: auto;
}

.logo-section{
  padding-top: 1rem;
  display: flex;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--forest-green);
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-content a {
  color: var(--white);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color on hover */
.dropdown-content a:hover {
  background-color: var(--light-green);
}

/* Show the dropdown content when the button is hovered over */
.dropdown:hover .dropdown-content {
  display: block;
}

/*IPAD PRO RESPONSIVENESS*/
@media only screen and (max-width: 1110px) {
  header-parent {
    width: 80%;
  }
}
@media only screen and (max-width: 970px) {

  .header-hamburger {
    display: block;
  }

  .logo{
    display: none;
  }
  .header-logo {
    font-size: 40px;
  }
  
  .header-parent {
    width: 100%;
    padding: 0 40px;
    position: relative;
  }
  .header-options {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -100%;
    transition: all 0.8s ease-out;
    top: 0px;
    height: 100vh;
    width: 100%;
    justify-content: space-around;
    background-color: #1f2235;
    z-index: 1000;
    opacity: 0.5;
  }
  .header-option-seperator {
    margin: 0;
  }
  .selected-header-option {
    color: var(--white);
  }
  .header-hamburger-bars {
    font-size: 36px;
    cursor: pointer;
    margin: 0px 10px;
    color: var(--white);
  }
  .show-hamburger-options {
    left: 0;
    opacity: 1;
  }
}
